<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'BlogPage',
});

const { $urls, $storyblokLivePreview, $mopI18n } = useNuxtApp();
const route = useRoute();
const { getCmsStory, cmsStoryModelRef, loadingRef } = useMopCms();
const { getBreadcrumbSchema } = useMopSeo();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage } = useMopSeo();
const classNameRef = ref(['']);

initTransition(classNameRef, loadingRef);

onMounted(async () => {
  await getCmsStory('/career/career-blog');
});

const cmsContentState = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const title = cmsStoryModelRef.value.getAttribute('title');
  const subTitle = cmsStoryModelRef.value.getAttribute('subTitle');

  return {
    title,
    subTitle,
  };
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  const blogLink: string = $mopI18n.localePath($urls.CAREER);

  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: blogLink,
    },
    {
      name: $mopI18n.t('locale.blog.name'),
      url: route.path,
    },
  ];

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(breadcrumbs),
  };
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['blog-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <h1 class="blog-page__headline">
        {{ cmsContentState.title }}
      </h1>
      <h2 class="blog-page__sub-headline">
        {{ cmsContentState.subTitle }}
      </h2>
      <div class="blog-page__items">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.blog-page {
  padding: $space30 $global-padding $space10 $global-padding;
}

.blog-page__headline {
  @include text-style(headline, 0);

  margin: 0;
  text-transform: uppercase;
}

.blog-page__sub-headline {
  @include text-style(strong);

  margin: 0 0 $space40;
  text-transform: uppercase;
}
</style>
